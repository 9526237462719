/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  IComponentContactInfo,
  IComponentGridInventory,
  IInventoryDetailsSettings,
  IInventorySettings,
  ILayoutBase,
  ILink,
  ILinkGroup,
  InventoryLocations,
} from '@lamesarv-sdk/types';

// -- Layout Utilities

export interface ILayoutGridSettings {
  inventoryNotFound: IInventorySettings;
  inventorySold: IInventorySettings;
  inventoryNotAvailable: IInventorySettings;
  inventoryLanding: IComponentGridInventory;
}

// -- Layout

export interface ILayoutHeader {
  slogan: string;
  searchPlaceholder?: string;
  sidebarLinks: ILink[];
  topBarLinks: ILink[];
  navBarLinks: ILink[];
  contact: IComponentContactInfo[];
}

export interface ILayoutFooter {
  copyright: string;
  emailOfferTitle: string;
  rvShoppingLinks: ILinkGroup;
  rvResourcesLinks: ILinkGroup;
  aboutLinks: ILinkGroup;
  locationLinks: ILinkGroup[];
  socialLinks: ILinkGroup;
  footerLinks: ILink[];
}

export interface ILayout extends ILayoutBase {
  header: ILayoutHeader;
  footer: ILayoutFooter;
  gridSettings: ILayoutGridSettings;
  inventoryDetailsSettings: IInventoryDetailsSettings;
  isPreview?: boolean;
  noPriceButtonContent?: string;
}

// -- Context

export interface ILayoutContextData {
  geoLocation?: [number, number];
  isGeoLocationLoading?: boolean;
  isComparisonModalOpen?: boolean;
  noPriceButtonContent?: string;
  inventoryLocations: InventoryLocations;
  detailsSettings: IInventoryDetailsSettings;
}

export enum LayoutReducerActionType {
  updateGeoLocation = 'updateGeoLocation',
  updateIsGeoLocationLoading = 'updateIsGeoLocationLoading',
  updateIsComparisonModalOpen = 'updateIsComparisonModalOpen',
  clear = 'clear',
}

export interface ILayoutReducerAction {
  type: LayoutReducerActionType;
  payload?: any;
}

/*
import { InventoryLocations } from '@lamesarv-sdk/types';

import { getLocationPages } from './getLocationPages';

export const getLocations = async (isPreview?: boolean): Promise<InventoryLocations> => {
  const pages = await getLocationPages(isPreview);

  return pages.map((location) => {
    const normalAliases = location.seo.routeAlias?.split('|').map((item) => item.trim()) || [];

    return {
      aliases: normalAliases.map((alias) => alias.toLowerCase()),
      normalAliases,
      route: (location.seo.route.startsWith('/') ? location.seo.route.substring(1) : location.seo.route).trim(),
      title: location.seo.title,
      address: location.extra?.address,
      selectName: location.extra?.select_name,
    };
  });
};
*/

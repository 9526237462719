import * as Yup from 'yup';

import { phoneRegex } from '@lamesarv-sdk/tools';

export const validationSchema = Yup.object().shape(
  {
    make: Yup.string().required('Make is required'),
    model: Yup.string().required('Model is required'),
    year: Yup.number().typeError('Year is required').min(1900).required('Year is required').integer(),
    miles: Yup.number().typeError('Miles is required').min(0).required('Miles is required').integer(),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email().required('Email is required'),
    phone: Yup.string()
      .required('Phone is required')
      .when('phone', {
        is: (value: string) => value?.length,
        then: (rule) => rule.matches(phoneRegex, 'Please enter a valid phone number.'),
      }),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().length(5, 'Please enter a 5 digit zip code').required('Zip is required'),
  },
  [['phone', 'phone']],
);

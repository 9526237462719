import { FieldError, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { twMerge } from 'tailwind-merge';

import { faCheckCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormApi } from '@lamesarv-sdk/hooks';
import { navigate } from '@lamesarv-sdk/tools';
import { IFormInput } from '@lamesarv-sdk/types';

import { IForm, IFormProps } from '../Form';
import * as FormClasses from '../Form.classes';
import { validationSchema } from './FormParts.schema';

interface IFormParts extends IForm {
  store: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  year: string;
  model: string;
  make: string;
  vin: string;
  comments: string;
}

export const FormParts = (props: IFormProps) => {
  const [loading, submitted, error, sendForm] = useFormApi();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormParts>({
    resolver: yupResolver(validationSchema) as unknown as Resolver<IFormParts>,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (formInput) => {
    await sendForm(formInput);

    reset();
    reset({
      phone: '',
    });

    if (props.redirect) {
      navigate(props.redirect);
    }
  };

  return (
    <form className={twMerge('relative p-2', props.className)} onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('formId')} value="45" />
      <input type="hidden" {...register('formType')} value="parts" />
      <div className="container">
        {loading && (
          <div
            className={
              'flex items-center w-full bg-gray-100 border-l-4 border-gray-500 text-gray-700 py-2 px-3 mb-4 mx-2 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faClock} className="w-8 mr-2" />
            <span>Sending...</span>
          </div>
        )}
        {submitted && !error && (
          <div
            className={
              'flex items-center w-full bg-green-100 border-l-4 border-green-500 text-green-700 py-2 px-3 mb-4 mx-2 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faCheckCircle} className="w-8 mr-2" />
            <span>Thank you for requesting more information.</span>
          </div>
        )}
        {submitted && error && (
          <div
            className={
              'flex items-center w-full bg-red-100 border-l-4 border-red-500 text-red-700 py-2 px-3 mb-4 mx-2 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="w-8 mr-2" />
            <span>An error has occurred, please try again later or contact us using alternative methods.</span>
          </div>
        )}
        <div className="flex flex-col">
          <div className="flex flex-col">
            <h3 className="mb-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">Personal Information</h3>
            <div className="my-2 px-2 w-full">
              <label className="text-sm text-gray-600">Preferred Service Center:</label>
              <select
                {...register('store')}
                className={errors.store ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
                defaultValue=""
              >
                <option value="" disabled>
                  Select an option:
                </option>
                <option value="_disabled" className="py-2" disabled>
                  CALIFORNIA
                </option>
                <option value="San Diego" className="py-2">
                  San Diego
                </option>
                <option value="Davis" className="py-2">
                  Davis
                </option>
                <option value="_disabled" className="py-2" disabled>
                  ARIZONA
                </option>
                <option value="Phoenix" className="py-2">
                  Phoenix
                </option>
                <option value="Tucson" className="py-2">
                  Tucson
                </option>
                <option value="_disabled" className="py-2" disabled>
                  NEW MEXICO
                </option>
                <option value="Albuquerque" className="py-2">
                  Albuquerque
                </option>
                <option value="_disabled" className="py-2" disabled>
                  FLORIDA
                </option>
                <option value="Ft. Myers" className="py-2">
                  Ft. Myers
                </option>
                <option value="Orlando" className="py-2">
                  Orlando
                </option>
                <option value="Port St. Lucie" className="py-2">
                  Port St. Lucie
                </option>
              </select>
              {errors.store && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.store as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('firstName')}
                placeholder="First Name*"
                className={errors.firstName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.firstName && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.firstName as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('lastName')}
                placeholder="Last Name*"
                className={errors.lastName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.lastName && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.lastName as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <InputMask
                mask="(999) 999-9999"
                {...register('phone')}
                placeholder="Phone*"
                className={errors.phone ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.phone && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.phone as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="email"
                {...register('email')}
                placeholder="Email*"
                className={errors.email ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.email && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.email as unknown as FieldError)?.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="my-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">Coach/RV Information</h3>
            <div className="my-2 px-2 w-full">
              <input
                type="number"
                min={0}
                step={1}
                {...register('year')}
                placeholder="Year"
                className={errors.year ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.year && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.year as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('make')}
                placeholder="Make"
                className={errors.make ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.make && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.make as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('model')}
                placeholder="Model"
                className={errors.model ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.model && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.model as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('vin')}
                placeholder="VIN"
                className={errors.vin ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.vin && (
                <div className={FormClasses.errorMessageClasses}>{(errors?.vin as unknown as FieldError)?.message}</div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <textarea
                {...register('comments')}
                placeholder="Parts Request"
                className={errors.comments ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.comments && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.comments as unknown as FieldError)?.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="p-2">
              <button
                type="submit"
                disabled={loading || !!Object.keys(errors).length}
                className={
                  Object.keys(errors).length ? FormClasses.buttonInactiveClasses : FormClasses.buttonNormalClasses
                }
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

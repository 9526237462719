'use client';

import { createContext, PropsWithChildren, useState } from 'react';

import { IBasicInventory } from '@lamesarv-sdk/types';

interface InventoryFormContextData {
  isMobileInquireOpen: boolean;
  item: IBasicInventory | null;
  openMobileInquire: (item: IBasicInventory) => void;
  closeMobileInquire: () => void;
}

export const InventoryFormContext = createContext({} as InventoryFormContextData);

export const InventoryFormProvider = ({ children }: PropsWithChildren) => {
  const [isMobileInquireOpen, setIsMobileInquireOpen] = useState(false);
  const [item, setItem] = useState<IBasicInventory | null>(null);

  const openMobileInquire = (item: IBasicInventory) => {
    setItem(item);
    setIsMobileInquireOpen(true);
  };

  const closeMobileInquire = () => {
    setIsMobileInquireOpen(false);
  };

  return (
    // sonar-ignore-start
    // Suppressing rule typescript:S6481 - The context value is provided by the parent component
    <InventoryFormContext.Provider value={{ isMobileInquireOpen, openMobileInquire, closeMobileInquire, item }}>
      {children}
    </InventoryFormContext.Provider>
    // sonar-ignore-end
  );
};

import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  phone: Yup.string().optional(),
  mobile: Yup.string().optional(),
  email: Yup.string().email().required('Email is required'),
  zipCode: Yup.string().required('ZipCode is required'),
  state: Yup.string().optional(),
  department: Yup.string().optional(),
  comments: Yup.string().optional(),
});

import { BroadcastChannel } from 'broadcast-channel';

const getBroadcast = (channel: string) => {
  if (typeof window === 'undefined') {
    throw new Error('BroadcastChannel is only available in the browser');
  }

  return new BroadcastChannel(channel);
};

export const getFavoritesChannel = () => getBroadcast('lamesa-channelFavorites');
export const getSelectionChannel = () => getBroadcast('lamesa-channelSelection');

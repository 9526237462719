'use client';

import { createContext, Dispatch, PropsWithChildren, useReducer } from 'react';

import { layoutReducer } from '@/reducers/layout';
import { ILayoutContextData, ILayoutReducerAction } from '@/types/layout';

export interface ILayoutContext {
  context: ILayoutContextData;
  dispatch: Dispatch<ILayoutReducerAction>;
}

export const LayoutContext = createContext<ILayoutContext>({} as ILayoutContext);

export const LayoutProvider = ({ children, ...props }: PropsWithChildren<ILayoutContextData>) => {
  const [context, dispatch] = useReducer(layoutReducer, props);

  const providerValue = {
    context,
    dispatch,
  };

  return <LayoutContext.Provider value={providerValue}>{children}</LayoutContext.Provider>;
};

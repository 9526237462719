'use client';
import { Fragment } from 'react';

import { FormType } from '@/types/forms';
import { IComponentForm } from '@lamesarv-sdk/types';

import { FormCareer } from './FormCareer/FormCareer';
import { FormContactInfo } from './FormContactInfo/FormContactInfo';
import { FormContactSell } from './FormContactSell/FormContactSell';
import { FormNewsletter } from './FormNewsletter/FormNewsletter';
import { FormNewsletterBlog } from './FormNewsletterBlog/FormNewsletterBlog';
import { FormNewsletterFooter } from './FormNewsletterFooter/FormNewsletterFooter';
import { FormNewsletterFooterNew } from './FormNewsletterFooter/FormNewsletterFooterNew';
import { FormParts } from './FormParts/FormParts';
import { FormServiceAppointment } from './FormServiceAppointment/FormServiceAppointment';

const getForm = (props: { formType: string; redirect?: string; className?: string }) => {
  switch (props.formType) {
    case FormType.career:
      return <FormCareer {...props} />;
    case FormType.contactInfo:
      return <FormContactInfo {...props} />;
    case FormType.contactSell:
      return <FormContactSell {...props} />;
    case FormType.newsletter:
      return <FormNewsletter {...props} />;
    case FormType.newsletterFooter:
      return <FormNewsletterFooter {...props} />;
    case FormType.newsletterFooterNew:
      return <FormNewsletterFooterNew {...props} />;
    case FormType.newsletterBlog:
      return <FormNewsletterBlog {...props} />;
    case FormType.serviceAppointment:
      return <FormServiceAppointment {...props} />;
    case FormType.parts:
      return <FormParts {...props} />;
    default:
      return <Fragment />;
  }
};

export interface IForm {
  formId: string;
  formType: string;
}

export interface IFormProps extends IComponentForm {
  className?: string;
}

export const Form = (props: IFormProps) => {
  return getForm(props);
};

import { FC, Suspense } from 'react';

export const withSuspense = <T extends object = object>(Component: FC<T>) => {
  const WrappedComponent = (props: T) => (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );

  return WrappedComponent;
};

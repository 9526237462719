import React from 'react';
import { FieldError, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { useFormApi } from '@lamesarv-sdk/hooks';
import { navigate } from '@lamesarv-sdk/tools';

import { validationSchema } from './FormNewsletterFooter.schema';
import * as FormClasses from '../Form.classes';

import { IForm, IFormProps } from '../Form';
import { IFormInput } from '@lamesarv-sdk/types';

interface IFormNewsletterFooter extends IForm {
  firstName: string;
  lastName: string;
  email: string;
}

export const FormNewsletterFooterNew = (props: IFormProps) => {
  const [loading, submitted, error, sendForm] = useFormApi();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormNewsletterFooter>({
    resolver: yupResolver(validationSchema) as unknown as Resolver<IFormNewsletterFooter>,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (formInput) => {
    await sendForm(formInput);

    reset();

    if (props.redirect) {
      navigate(props.redirect);
    }
  };

  return (
    <form className={twMerge('', props.className)} onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('formId')} value="28" />
      <input type="hidden" {...register('formType')} value="email_newsletter" />
      <div className="grid grid-cols-4">
        {loading && (
          <div
            className={
              'flex items-center w-full bg-gray-100 border-l-4 border-gray-500 text-gray-700 py-2 px-3 mb-4 shadow-lg col-span-3'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faClock} className="w-8 mr-2" />
            <span>Sending...</span>
          </div>
        )}
        {submitted && !error && (
          <div
            className={
              'flex items-center w-full bg-green-100 border-l-4 border-green-500 text-green-700 py-2 px-3 mb-4 shadow-lg col-span-3'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faCheckCircle} className="w-8 mr-2" />
            <span>Thank you for requesting more information.</span>
          </div>
        )}
        {submitted && error && (
          <div
            className={
              'flex items-center w-full bg-red-100 border-l-4 border-red-500 text-red-700 py-2 px-3 mb-4 shadow-lg col-span-3'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="w-8 mr-2" />
            <span>An error has occurred, please try again later or contact us using alternative methods.</span>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-7 lg:-mx-2 justify-left relative">
        <div className="col-span-2 py-2">
          <input
            type="text"
            {...register('firstName')}
            placeholder="First Name*"
            className="w-full h-10 mt-3 lg:mt-0 px-4 py-2 text-white border border-neutral-500 bg-transparent focus:ring-white"
          />
          {errors.firstName && (
            <div className={FormClasses.errorMessageClasses}>
              {(errors?.firstName as unknown as FieldError)?.message}
            </div>
          )}
        </div>
        <div className="col-span-2 py-2">
          <input
            type="text"
            {...register('lastName')}
            placeholder="Last Name*"
            className="w-full h-10 mt-3 lg:mt-0 px-4 py-2 text-white border border-neutral-500 bg-transparent focus:ring-white"
          />
          {errors.lastName && (
            <div className={FormClasses.errorMessageClasses}>
              {(errors?.lastName as unknown as FieldError)?.message}
            </div>
          )}
        </div>
        <div className="col-span-2 py-2">
          <input
            type="email"
            {...register('email')}
            placeholder="Email*"
            className="w-full h-10 mt-3 lg:mt-0 px-4 py-2 text-white border border-neutral-500 bg-transparent focus:ring-white"
          />
          {errors.email && (
            <div className={FormClasses.errorMessageClasses}>{(errors?.email as unknown as FieldError)?.message}</div>
          )}
        </div>
        <div className="col-span-1 py-2">
          <button
            type="submit"
            disabled={loading}
            className="w-full h-10 mt-3 lg:mt-0 px-4 py-2 text-white text-sm border border-neutral-500 bg-transparent font-bold uppercase hover:bg-neutral-600 active:border-white"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

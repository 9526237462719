export const inputBaseClasses =
  'w-full block py-2 px-3 focus:outline-none sm:text-sm rounded-sm border disabled:bg-gray-50 disabled:opacity-80';
export const inputNormalClasses = inputBaseClasses + ' border-gray-300';
export const inputFooterClasses =
  inputBaseClasses +
  ' h-12 px-4 py-3 mt-3 lg:mt-0  leading-tight text-sage-700 border border-white border-r-1 border-r-sage-400 rounded-md lg:rounded-l-md lg:rounded-r-none disabled:cursor-not-allowed disabled:opacity-75';

export const inputErrorClasses = inputBaseClasses + ' bg-red-100 border-red-300';

export const buttonBaseClasses =
  'text-center font-bold text-opacity-90 rounded-md shadow-sm hover:shadow-md text-sm uppercase';

export const buttonPrimaryClasses =
  ' cursor-pointer text-opacity-90 text-white px-4 py-3 uppercase rounded-sm bg-[#1E1E1E] border border-transparent hover:border-black hover:bg-white hover:text-black ';
export const buttonSecondaryClasses = ' text-blue border border-gray-100 bg-white hover:bg-gray-50 cursor-pointer';

export const buttonNormalClasses = buttonBaseClasses + buttonPrimaryClasses + ' px-4 py-3';
export const buttonFlatClasses = buttonBaseClasses + buttonSecondaryClasses + ' px-4 py-3';

export const buttonInactiveClasses = buttonBaseClasses + ' text-gray-600 bg-gray-300 cursor-not-allowed px-4 py-3';

export const errorMessageClasses = 'text-xs text-red-600';

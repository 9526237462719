import { ILayoutContextData, LayoutReducerActionType, ILayoutReducerAction } from '@/types/layout';

export const layoutReducer = (state: ILayoutContextData, action: ILayoutReducerAction) => {
  switch (action.type) {
    case LayoutReducerActionType.updateGeoLocation:
      return {
        ...state,
        geoLocation: action.payload,
      };
    case LayoutReducerActionType.updateIsGeoLocationLoading:
      return {
        ...state,
        isGeoLocationLoading: action.payload,
      };
    case LayoutReducerActionType.updateIsComparisonModalOpen:
      return {
        ...state,
        isComparisonModalOpen: action.payload,
      };
    default:
      return state;
  }
};

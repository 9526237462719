import * as Yup from 'yup';

import { phoneRegex } from '@lamesarv-sdk/tools';

export const validationSchema = Yup.object().shape(
  {
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email().required('Email is required'),

    zipCode: Yup.string().length(5, 'Please enter a 5 digit zip code').required('Zip is required'),

    phone: Yup.string()
      .notRequired()
      .when('phone', {
        is: (value: string) => value?.length,
        then: (rule) => rule.matches(phoneRegex, 'Please enter a valid phone number.'),
      }),

    mobile: Yup.string()
      .notRequired()
      .when('mobile', {
        is: (value: string) => value?.length,

        then: (rule) => rule.matches(phoneRegex, 'Please enter a valid phone number.'),
      }),
  },
  [
    ['phone', 'phone'],
    ['mobile', 'mobile'],
  ],
);

import React from 'react';
import { FieldError, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { twMerge } from 'tailwind-merge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faClock } from '@fortawesome/free-regular-svg-icons';

import { useFormApi } from '@lamesarv-sdk/hooks';
import { navigate } from '@lamesarv-sdk/tools';

import { IForm } from '../Form';
import { validationSchema } from './FormNewsletter.schema';
import * as FormClasses from '../Form.classes';

import { IFormProps } from '../Form';
import { IFormInput } from '@lamesarv-sdk/types';

interface IFormNewsletter extends IForm {
  firstName: string;
  lastName: string;
  email: string;
}

export const FormNewsletter = (props: IFormProps) => {
  const [loading, submitted, error, sendForm] = useFormApi();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormNewsletter>({
    resolver: yupResolver(validationSchema) as unknown as Resolver<IFormNewsletter>,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (formInput) => {
    await sendForm(formInput);

    reset();

    if (props.redirect) {
      navigate(props.redirect);
    }
  };

  return (
    <form className={twMerge('relative p-2', props.className)} onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('formId')} value="28" />
      <input type="hidden" {...register('formType')} value="email_newsletter" />
      {loading && (
        <div
          className={
            'flex items-center w-full bg-gray-100 border-l-4 border-gray-500 text-gray-700 py-2 px-3 mb-4 shadow-lg'
          }
          role="alert"
        >
          <FontAwesomeIcon icon={faClock} className="w-8 mr-2" />
          <span>Sending...</span>
        </div>
      )}
      {submitted && !error && (
        <div
          className={
            'flex items-center w-full bg-green-100 border-l-4 border-green-500 text-green-700 py-2 px-3 mb-4 shadow-lg'
          }
          role="alert"
        >
          <FontAwesomeIcon icon={faCheckCircle} className="w-8 mr-2" />
          <span>Thank you for requesting more information.</span>
        </div>
      )}
      {submitted && error && (
        <div
          className={
            'flex items-center w-full bg-red-100 border-l-4 border-red-500 text-red-700 py-2 px-3 mb-4 shadow-lg'
          }
          role="alert"
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className="w-8 mr-2" />
          <span>An error has occurred, please try again later or contact us using alternative methods.</span>
        </div>
      )}
      <div className="container">
        <div className="flex flex-col my-2">
          <input
            type="text"
            {...register('firstName')}
            placeholder="First Name*"
            className={errors.firstName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
          />
          {errors.firstName && (
            <div className={FormClasses.errorMessageClasses}>
              {(errors?.firstName as unknown as FieldError)?.message}
            </div>
          )}
        </div>
        <div className="flex flex-col my-2">
          <input
            type="text"
            {...register('lastName')}
            placeholder="Last Name*"
            className={errors.lastName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
          />
          {errors.lastName && (
            <div className={FormClasses.errorMessageClasses}>
              {(errors?.lastName as unknown as FieldError)?.message}
            </div>
          )}
        </div>
        <div className="flex flex-col my-2">
          <input
            type="email"
            {...register('email')}
            placeholder="Email*"
            className={errors.email ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
          />
          {errors.email && (
            <div className={FormClasses.errorMessageClasses}>{(errors?.email as unknown as FieldError)?.message}</div>
          )}
        </div>
        <div className="my-2">
          <button
            type="submit"
            disabled={loading || !!Object.keys(errors).length}
            className={Object.keys(errors).length ? FormClasses.buttonInactiveClasses : FormClasses.buttonNormalClasses}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

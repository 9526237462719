import React from 'react';
import { FieldError, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { twMerge } from 'tailwind-merge';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faClock } from '@fortawesome/free-regular-svg-icons';

import { useFormApi } from '@lamesarv-sdk/hooks';
import { navigate } from '@lamesarv-sdk/tools';

import { IForm } from '../Form';
import { validationSchema } from './FormCareer.schema';
import * as FormClasses from '../Form.classes';

import { IFormProps } from '../Form';
import { IFormInput } from '@lamesarv-sdk/types';

interface IFormCareer extends IForm {
  firstName: string;
  lastName: string;
  phone?: string;
  mobile?: string;
  email: string;
  zipCode: string;
  state?: string;
  department?: string;
  comments?: string;
}

export const FormCareer = (props: IFormProps) => {
  const [loading, submitted, error, sendForm] = useFormApi();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormCareer>({
    resolver: yupResolver(validationSchema) as unknown as Resolver<IFormCareer>,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (formInput) => {
    await sendForm(formInput);

    reset();
    reset({
      phone: '',
      mobile: '',
    });

    if (props.redirect) {
      navigate(props.redirect);
    }
  };

  return (
    <form className={twMerge('relative p-2', props.className)} onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('formId')} value="30" />
      <input type="hidden" {...register('formType')} value="career" />
      <div className="container">
        <div className="flex flex-col">
          <div className="flex flex-col">
            {loading && (
              <div
                className={
                  'flex items-center w-full bg-gray-100 border-l-4 border-gray-500 text-gray-700 py-2 px-3 mb-4 mx-2 shadow-lg'
                }
                role="alert"
              >
                <FontAwesomeIcon icon={faClock} className="w-8 mr-2" />
                <span>Sending...</span>
              </div>
            )}
            {submitted && !error && (
              <div
                className={
                  'flex items-center w-full bg-green-100 border-l-4 border-green-500 text-green-700 py-2 px-3 mb-4 mx-2 shadow-lg'
                }
                role="alert"
              >
                <FontAwesomeIcon icon={faCheckCircle} className="w-8 mr-2" />
                <span>Thank you for requesting more information.</span>
              </div>
            )}
            {submitted && error && (
              <div
                className={
                  'flex items-center w-full bg-red-100 border-l-4 border-red-500 text-red-700 py-2 px-3 mb-4 mx-2 shadow-lg'
                }
                role="alert"
              >
                <FontAwesomeIcon icon={faExclamationTriangle} className="w-8 mr-2" />
                <span>An error has occurred, please try again later or contact us using alternative methods.</span>
              </div>
            )}
            <h3 className="mb-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">Personal Information</h3>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('firstName')}
                placeholder="First Name"
                className={errors.firstName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.firstName && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.firstName as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('lastName')}
                placeholder="Last Name"
                className={errors.lastName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.lastName && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.lastName as unknown as FieldError)?.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="mt-3 mb-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">Contact Information</h3>
            <div className="my-2 px-2 w-full">
              <InputMask
                mask="(999) 999-9999"
                {...register('phone')}
                placeholder="Phone"
                className={errors.phone ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.phone && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.phone as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <InputMask
                mask="(999) 999-9999"
                {...register('mobile')}
                placeholder="Mobile Phone"
                className={errors.phone ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.mobile && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.mobile as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="email"
                {...register('email')}
                placeholder="Email"
                className={errors.email ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.email && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.email as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('zipCode')}
                placeholder="Zip Code"
                className={errors.zipCode ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.zipCode && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.zipCode as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <h3 className="mt-3 mb-3 w-full text-md text-sage-700 uppercase font-bold">
                State You&apos;d Like to Work In:
              </h3>
              <select
                {...register('state')}
                className={errors.state ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              >
                <option disabled selected>
                  Select an option:
                </option>
                <option value="Arizona">Arizona</option>
                <option value="California">California</option>
                <option value="Florida">Florida</option>
                <option value="New Mexico">New Mexico</option>
              </select>
              {errors.state && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.state as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <h3 className="mt-3 mb-3 w-full text-md text-sage-700 uppercase font-bold">Requested Position:</h3>
              <select
                {...register('department')}
                className={errors.department ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              >
                <option disabled selected>
                  Select an option:
                </option>
                <option value="Sales">Sales</option>
                <option value="Service">Service</option>
                <option value="Parts">Parts</option>
                <option value="Office">Office</option>
                <option value="Part-time Driver">Part-time Driver</option>
              </select>
              {errors.department && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.department as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <textarea
                {...register('comments')}
                placeholder="Comments"
                className={errors.comments ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.comments && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.comments as unknown as FieldError)?.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="p-2">
              <button
                type="submit"
                disabled={loading || !!Object.keys(errors).length}
                className={
                  Object.keys(errors).length ? FormClasses.buttonInactiveClasses : FormClasses.buttonNormalClasses
                }
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {!loading && !error && (
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-gray-800 opacity-90">
          <span className="absolute left-4 top-1/2 -translate-y-1/2 text-white font-bold opacity-1">
            Thank you for your interest.
          </span>
        </div>
      )}
    </form>
  );
};

import React from 'react';
import { FieldError, useForm, SubmitHandler, Resolver } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { useFormApi } from '@lamesarv-sdk/hooks';
import { navigate } from '@lamesarv-sdk/tools';

import { validationSchema } from './FormNewsletterFooter.schema';
import * as FormClasses from '../Form.classes';

import { IForm, IFormProps } from '../Form';
import { IFormInput } from '@lamesarv-sdk/types';

interface IFormNewsletterFooter extends IForm {
  firstName: string;
  lastName: string;
  email: string;
}

export const FormNewsletterFooter = (props: IFormProps) => {
  const [loading, submitted, error, sendForm] = useFormApi();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormNewsletterFooter>({
    resolver: yupResolver(validationSchema) as unknown as Resolver<IFormNewsletterFooter>,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (formInput) => {
    await sendForm(formInput);

    reset();

    if (props.redirect) {
      navigate(props.redirect);
    }
  };

  return (
    <form className={twMerge('', props.className)} onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('formId')} value="28" />
      <input type="hidden" {...register('formType')} value="email_newsletter" />
      <div className="grid grid-cols-4">
        {loading && (
          <div
            className={
              'flex items-center w-full bg-gray-100 border-l-4 border-gray-500 text-gray-700 py-2 px-3 mb-4 shadow-lg col-span-3'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faClock} className="w-8 mr-2" />
            <span>Sending...</span>
          </div>
        )}
        {submitted && !error && (
          <div
            className={
              'flex items-center w-full bg-green-100 border-l-4 border-green-500 text-green-700 py-2 px-3 mb-4 shadow-lg col-span-3'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faCheckCircle} className="w-8 mr-2" />
            <span>Thank you for requesting more information.</span>
          </div>
        )}
        {submitted && error && (
          <div
            className={
              'flex items-center w-full bg-red-100 border-l-4 border-red-500 text-red-700 py-2 px-3 mb-4 shadow-lg col-span-3'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="w-8 mr-2" />
            <span>An error has occurred, please try again later or contact us using alternative methods.</span>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 justify-left relative">
        <div className="w-full">
          <input
            type="text"
            {...register('firstName')}
            placeholder="First Name*"
            className={
              'w-full h-12 px-4 py-3 mt-3 lg:mt-0 leading-tight text-sage-700 border border-white border-r-1 border-r-sage-400 rounded-md lg:rounded-l-md lg:rounded-r-none disabled:cursor-not-allowed disabled:opacity-75'
            }
          />
          {errors.firstName && (
            <div className={FormClasses.errorMessageClasses}>
              {(errors?.firstName as unknown as FieldError)?.message}
            </div>
          )}
        </div>
        <div className="w-full">
          <input
            type="text"
            {...register('lastName')}
            placeholder="Last Name*"
            className={
              'w-full h-12 px-4 py-3 mt-3 lg:mt-0  leading-tight text-sage-700 border border-white border-r-1 border-r-sage-400 rounded-md lg:rounded-none disabled:cursor-not-allowed disabled:opacity-75'
            }
          />
          {errors.lastName && (
            <div className={FormClasses.errorMessageClasses}>
              {(errors?.lastName as unknown as FieldError)?.message}
            </div>
          )}
        </div>
        <div className="w-full">
          <input
            type="email"
            {...register('email')}
            placeholder="Email*"
            className={
              'w-full h-12 mt-3 lg:mt-0  px-4 py-3 leading-tight text-sage-700 border border-white rounded-md lg:rounded-none disabled:cursor-not-allowed disabled:opacity-75'
            }
          />
          {errors.email && (
            <div className={FormClasses.errorMessageClasses}>{(errors?.email as unknown as FieldError)?.message}</div>
          )}
        </div>
        <button
          type="submit"
          disabled={loading}
          className={
            'w-16 h-12 items-center mt-3 lg:mt-0 px-4 py-3 text-sage-900 rounded-md lg:rounded-none lg:rounded-r-md bg-sage-400 sm:text-sm uppercase font-bold hover:text-white hover:bg-sage-600 hover:disabled:bg-sage-300  hover:disabled:text-sage-900  active:text-white  active:bg-sage-700  active:outline-0 focus:bg-sage-600  focus:border-sage-300 focus:outline-4 disabled:opacity-25  disabled:bg-sage-300  disabled:animate-pulse disabled:transform-none disabled:transition-none  disabled:cursor-not-allowed '
          }
        >
          GO
        </button>
      </div>
    </form>
  );
};
